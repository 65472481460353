<template>
    <div class="main">
        <div class="row">
            <div class="col">
                <div class="content">
                    <div class="card" v-for="(item, index) in result" :key="index"
                        :class="{ cardOnFilter: !show_Div_FilterBox }">
                        <div class="profile-followers">
                            <div class="profil">
                                <img :src="minioUrl(item._id)" alt="profile" />
                            </div>
                            <div class="followers">
                                <div class="count-followers">
                                    <div>
                                        <p>{{ intToString(item.influencer.follower) }}</p>
                                        <small>Followers</small>
                                    </div>
                                    <div>
                                        <p>{{ item.count }}</p>
                                        <small>Matched Photos</small>
                                    </div>
                                </div>
                                <div class="myBtn">
                                    <a class="btn btn-seeMore" :href="'https://instagram.com/' + item._id"
                                        target="_blank">See more</a>
                                </div>
                            </div>
                        </div>
                        <div class="boxID">
                            <img src="../../../assets/instagram-vector.svg" alt="instagram" />
                            <p>{{ item._id }}</p>
                        </div>
                        <vueper-slides class="no-shadow" :visible-slides="3" slide-multiple :gap="3" :slide-ratio="1 / 4"
                            :dragging-distance="200" :arrows-outside="false" :bullets="false" @click="toggler = !toggler">
                            <vueper-slide v-for="(post, index) in item.posts" :key="index" :title="le"
                                :image="minioUrl(post, item._id)" class="posts">
                            </vueper-slide>
                        </vueper-slides>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { tagColors } from '../../../unicodeColor.js';
import { VueperSlides, VueperSlide } from 'vueperslides';
import CoolLightBox from 'vue-cool-lightbox';
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css';
import VueEasyLightbox from 'vue-easy-lightbox';
import { ref } from 'vue';
export default {
    components: { VueperSlides, VueperSlide, CoolLightBox, VueEasyLightbox },
    props: {
        show_Div_FilterBox: Array,
        result: Object,
        matchTags: Object,
    },

    setup() {
        const toggler = ref(false);
        // function for get random color and set for tags;
        const getRandomColor = () => {
            let randomIndex = Math.floor(Math.random() * tagColors.length);
            return tagColors[randomIndex];
        };

        const onHide = () => (toggler.value = false);

        const minioUrl = (path, username = '') => {
            if (username == '') {
                username = path;
            }
            return `https://minio-api.farazpardazan.com/yalda/${username}/${path}.jpeg`;
        };

        const intToString = (num) => {
            try {
                num = num.toString().replace(/[^0-9.]/g, '');
            } catch (e) {
                num = 10000
            } finally {
            }
            if (num < 1000) {
                return num;
            }
            let si = [
                { v: 1e3, s: 'K' },
                { v: 1e6, s: 'M' },
                { v: 1e9, s: 'B' },
                { v: 1e12, s: 'T' },
                { v: 1e15, s: 'P' },
                { v: 1e18, s: 'E' },
            ];
            let index;
            for (index = si.length - 1; index > 0; index--) {
                if (num >= si[index].v) {
                    break;
                }
            }
            return (num / si[index].v).toFixed(2).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, '$1') + si[index].s;
        };

        return { getRandomColor, intToString, toggler, onHide, minioUrl };
    },
};
</script>

<style scoped lang="scss">
@import './cardView.scss';
</style>
